<template lang="pug">
.wrap
	LayoutPaymentFailed(v-if="user?.payment_failed")
	VitePwaManifest
	canvas.layout-canvas(ref="layoutCanvas" :style="{ background: linearBackground }")
	LayoutNav(v-if="settings" :story="settings")
	LayoutPlaceholderNav(v-else)

	.page
		slot(keep-alive)
	LayoutSingaFooter.footer(v-if="settings" :story="settings")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~~/pinia/queueStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'
import { useUserStore } from '~~/pinia/userStore'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const { locale } = useI18n()
const layoutCanvas: any = ref(null)
const customColor = ref('#12B961')

const setLang = (language: any) => {
	useHead({
		htmlAttrs: {
			lang: language
		}
	})
}
const { data: settings } = useNuxtData('settings-' + locale.value)
const { $preview } = useNuxtApp()
const config = useRuntimeConfig()
const token = config.public.storyblokAccessToken
const version = $preview ? 'draft' : 'published'

// Fetch the settings
await useAsyncData(
	'settings-' + locale.value,
	async () => {
		try {
			const defUrl = `https://api.storyblok.com/v2/cdn/stories/settings?&token=${token}&language=${locale.value}&cv=0&resolve_links=url&version=${version}`
			const response: any = await $fetch(defUrl)

			return response.story.content
		} catch (fallbackError) {
			console.error('Settings fetch failed:', fallbackError)
			return {} // Return an empty object or a default structure
		}
	},
	{
		watch: [locale],
		deep: true
	}
)

const linearBackground = computed(() => `linear-gradient(${customColor.value}, black)`)

watch(locale, (value) => {
	setLang(value)
})

onMounted(() => {
	setLang(locale)
	const queueStore = useQueueStore()
	const secondScreenStore = useSecondScreenStore()
	queueStore.$hydrate({ runHooks: false })
	// queueStore.nullifySongPlaying()
	window.onbeforeunload = () => {
		if (secondScreenStore.isSecondScreenActive) {
			secondScreenStore.closeConnection()
		}
	}
	useEventOn('layoutGradient:applyColor', (color) => {
		customColor.value = color
	})

	// // Test to set the geoLocation after a delay to see if stuff refreshes
	// setTimeout(() => {
	// 	const geoLocationStore = useGeoLocationStore()
	// 	geoLocationStore.setGeoLocation('fi')
	// 	console.log(geoLocationStore.contentMarket)
	// }, 5000)

	useEventOn('layoutGradient:resetDefault', () => { customColor.value = '#12B961' })
})

onUnmounted(() => {
	useEventOff('layoutGradient:applyColor')
	useEventOff('layoutGradient:resetDefault')
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100svh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	overflow: hidden
	min-height: 100vh
.footer
	margin-top: auto
.relative-wrapper
	position: relative
</style>
